<template>
  <div class="search-box" :style="styleObject">
    <ul class="type-list">
      <li v-for="(item, index) in types"
          :class="{ 'active': index + 1 === current}"
          @click="current = index + 1">{{ item }}</li>
    </ul>
    <div class="input-wrap">
      <transition name="slide-fade">
        <div :class="{'copy-mask': true, 'mobile-copy-mask': $device.isMobileOrTablet}" v-if="copyText">
          <p>检测到复制<span>“{{ copyText.length > 16 ? copyText.slice(0, 16) + '...' : copyText}}”</span>内容，是否粘贴内容到搜索框？</p>
          <div class="btn-group">
            <button v-wave @click="clearClipboard()">不用了</button>
            <button v-wave @click="clearClipboard(copyText)">确认粘贴</button>
          </div>
          <div class="radio" @click="handleChoose">
            <div class="radio-circle" :class="{ selected: radio }">
              <div class="radio-inner-circle" ></div>
            </div>
            <span class="radio-label">不再提醒复制内容</span>
          </div>
        </div>
      </transition>
      <div class="search-wrap">
        <textarea v-model="word"
                  ref="textRef"
                  @input="onInput"
                  @focus="onFocus"
                  @keydown.enter.prevent="sendMessage"
                  placeholder="有问题就有答案，试试在这里输入你的问题吧..."
                  :maxlength="maxChars"></textarea>
        <div class="tool-bar" v-if="!isFold">
          <span :style="{ color: word.length >= maxChars ? '#FF0404' : '' }">
            <i :style="{ color: word.length > 0 && word.length < maxChars ? '#0479FF' : '' }">{{ word.trim().length }}</i>
            <span style="margin: 0 2px 0 2px">/</span>{{ maxChars }}字
          </span>
          <div class="btn-group">
<!--            <button v-wave-->
<!--                    :class="{ 'disable-btn': word.length < 4 || word.length > maxChars, 'ai-btn': true }"-->
<!--                    @click="sendMessage({ai: true})">AI搜索</button>-->
            <button v-wave
                    :class="{ 'disable-btn': word.length < 4 || word.length > maxChars, 'text-btn': true }"
                    @click="sendMessage">搜索</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>

const current = ref(1);
const copyText = ref('');
const textRef = ref();
const { isMobileOrTablet } = useDevice();
const types = reactive(['搜标题', '搜题干', '搜选项'])
const maxChars = 200;
const radio = ref(false);
const {isFold: fold, word: q, isMobile} = defineProps({
  isFold: { // 是否缩小折叠输入框
    type: Boolean,
    default: false
  },
  word: {
    type: String,
    default: ''
  },
  isMobile: {
    type: Boolean,
    default: false
  }
});
const word = ref(q);
const isFold = ref(fold);
const styleObject = computed(() => {
   const object = {
     '--input-box-height': isMobile ? '190px' : isFold.value ? '128px' : '244px', // 例如，根据 isFold 设置输入框的宽度
     '--input-wrap-height': isMobile ? '136px' : isFold.value ? '44px' : '160px',
     '--input-wrap-padding': isMobile ? '8px' : isFold.value ? '10px 20px' : '20px 20px 16px 20px',
     '--input-placeholder-size': isMobile ? '14px' : isFold.value ? '16px' : '18px',
     '--input-tab-size': isMobile? '12px' : isFold.value ? '20px' : '22px',
   };
   if (isMobile && isFold.value) {
     object['--input-box-height'] = 'auto';
     object['--input-wrap-height'] = 'auto';
     object['--search-box-padding'] = '14px';
   }
  return object
});
const handleChoose = () => {
  radio.value = !radio.value;
  localStorage.setItem('is-remind', radio.value ? '1' : '0');
}
const onInput = (event) => {

  if (word.value.length > maxChars) {
    event.preventDefault(); // 阻止继续输入
  }
};
const sendMessage = (event) => {

  if (event.key === 'Enter') {
    if (event.shiftKey) {
      // 这里开始换行
      const textarea = event.target;
      const cursorPos = textarea.selectionStart;
      const value = textarea.value;
      textarea.value = value.slice(0, cursorPos) + '\n' + value.slice(cursorPos);
      textarea.selectionStart = textarea.selectionEnd = cursorPos + 1;
      return;
    } else {
      event.preventDefault();
    }
  }
  if (word.value.length >= 4 && word.value.length <= maxChars) {
    if (event.ai) {
      window.open(isMobileOrTablet ? `https://h5.ppkao.com/#/chat?word=${word.value}` : `https://www.ppkao.com/ai?q=${word.value}&model=ai`)
    } else {
      navigateTo({path: '/so', query: {q: word.value, way: current.value - 1}, replace: fold })
    }

    if (!fold) word.value = '';

  }
};

// 输入框聚焦读取粘贴板
const onFocus = async () => {
  if (process.client) {
    if (!radio.value) {
      try {
        if (fold) textRef.value.select();

        // 读取剪贴板内容
        const text = await navigator?.clipboard.readText();
        copyText.value = text;  // 如果需要将剪贴板内容填入textarea
      } catch (err) {
        console.error('Failed to read clipboard contents:', err);
      }
    }
    isFold.value = false;
  }
};
// 清空粘贴板
const clearClipboard = async (value = '') => {
  if (process.client) {
    try {
      await navigator.clipboard.writeText('');
      copyText.value = '';
      if (value) word.value = value.slice(0, 200);
      textRef.value.focus();
    } catch (err) {
      console.error('Failed to clear clipboard:', err);
    }
  }
}
if (process.client) {
  radio.value = localStorage?.getItem('is-remind') === '1';
}

</script>

<style scoped lang="scss">
.search-box {
  width: 100%;
  height: var(--input-box-height);
  transition: all .3s;
  background: url("https://static.ppkao.com/www/images/search-bg.png") no-repeat, linear-gradient(0deg, rgba(179, 199, 233, var(--bg-opacity)), rgba(0, 120, 255, var(--bg-opacity)));
  border-radius: 8px;
  padding: var(--search-box-padding);
  .type-list {
    display: flex;
    align-items: flex-end;
    color: rgba(255,255,255,0.5);
    font-size: var(--input-placeholder-size);
    height: var(--input-tab-size);
    li {
      margin-right: var(--search-li-mr);
      cursor: pointer;
      transition: font-size .15s;
      user-select: none;
    }
    .active {
      font-size: var(--input-tab-size);
      transition: all .3s;
      color: #FFFFFF;
    }
  }
  .input-wrap {
    width: 100%;
    height: var(--input-wrap-height);
    margin-top: var(--input-wrap-pt);
    background-color: var(--border-color);
    transition: all .3s;
    border-radius: 14px;
    overflow: hidden;
    @include flex-col;
    position: relative;
    .search-wrap {
      width: 100%;
      height: 100%;
      @include flex-col;
      padding: var(--input-wrap-padding);
      transition: all .3s;
      textarea {
        width: 100%;
        resize: none;
        overflow: auto;
        flex: 1;
        line-height: var(--search-linheight);
        color: var(--title-color);
        &::placeholder {
          @include font(#9D9DB0, var(--input-placeholder-size));
          letter-spacing: 2px;
        }
        &::-webkit-scrollbar {
          width: 4px; /* 滚动条宽度 */
          cursor: pointer;
        }

        &::-webkit-scrollbar-thumb {
          background: #D6E3E9; /* 滚动条滑块颜色 */
          border-radius: 4px; /* 滚动条滑块圆角 */
        }

        &:hover::-webkit-scrollbar {
          width: 8px; /* 悬停时滚动条宽度 */
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1; /* 滚动条轨道颜色 */
        }
      }
    }
    .copy-mask {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: var(--card-bg-color);
      @include flex-col-center;
      justify-content: center;
      p {
        @include font(var(--primary-text-color), var(--search-btn-font-size), bold);
        span {
          @include font(#0078FF, 20px, bold);
        }
      }
      .btn-group {
        @include flex-center;
        margin-top: 20px;
        button:nth-child(1) {
          @include btn(120px, 44px, var(--search-bg-color), 8px, 16px, #0078FF);
          margin-right: 4px;
        }
        button:nth-child(2) {
          @include btn(120px, 44px, linear-gradient(180deg, #0379FF, #76B4FF), 8px, 16px, #fff);
        }
      }
    }
    .mobile-copy-mask {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: var(--card-bg-color);
      @include flex-col-center;
      justify-content: center;
      p {
        padding: 0 30px;
        text-align: center;
        line-height: 20px;
        @include font(var(--primary-text-color), 16px, bold);
        span {
          @include font(#0078FF, 14px, bold);
        }
      }
      .btn-group {
        @include flex-center;
        margin-top: 10px;
        button:nth-child(1) {
          @include btn(80px, 30px, var(--search-bg-color), 8px, 14px, #0078FF);
          margin-right: 4px;
        }
        button:nth-child(2) {
          @include btn(80px, 30px, linear-gradient(180deg, #0379FF, #76B4FF), 8px, 14px, #fff);
        }
      }
    }
  }
  .tool-bar {
    @include flex-bet-center;
    align-items: flex-end;

    span {
      @include font(var(--title-color), var(--search-tool-font));
    }

    .btn-group {
      .text-btn {
        @include btn(var(--search-btn-w), var(--search-btn-h), linear-gradient(0deg, #76B4FF, #0379FF), 10px, var(--search-btn-font-size));
        color: #FFFFFF;
      }
      .ai-btn {
        margin-right: 6px;
        @include btn(var(--search-btn-w), var(--search-btn-h), linear-gradient(-48deg, #6359BC, #0F0B12, #0F0B12, #632D62), 10px, var(--search-btn-font-size));
        color: #FFFFFF;
      }
      .disable-btn {
        background: #BFCBD1;
      }
    }
  }
}
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.22s ease-out;
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-100%);
}
.radio {
  margin-top: 14px;
  @include flex-y-center;
  cursor: pointer;
  &-circle {
    @include circle(14px);
    @include flex-center;
    border: 1px solid #dcdfe6;
    margin-right: 8px;
    position: relative;
    transition: transform .15s ease-in;
  }

  &-circle.selected {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    .radio-inner-circle{
      transform: scale(1);
    }
  }

  &-inner-circle {
    @include circle(4px);
    background-color: white;
    transform: scale(0);
    transition: transform .15s ease-in;
  }
  &-label {
    @include font(var(--primary-text-color), 14px)
  }
}
</style>
